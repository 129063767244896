import { Component, OnInit } from "@angular/core";
import { WizardStep, WizardParam, NewStep } from "./wizard.model";
import { WizardService } from "./wizard.service";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { RoutePath } from "./../shared/constants/globals.constant";
import {
  Payment,
  Transaction,
} from "../shared/models/payment-transaction.model";
import { PaymentTransactionService } from "../shared/services/payment-transaction/payment-transaction.service";
import { Subscription } from "rxjs";
import { ModalConfig } from "../shared/models/modal.model";

@Component({
  selector: "app-wizard",
  templateUrl: "./wizard.component.html",
  styleUrls: ["./wizard.component.scss"],
})
export class WizardComponent implements OnInit {
  public wizardSteps = new Array();
  public currentStep: string;
  public initialStep: number;
  public numberSteps: number;

  public displayDeleteCoupon: boolean = false;

  constructor(
    private wizardService: WizardService,
    private router: Router,
    private paymentTransactionService: PaymentTransactionService
  ) {}

  ngOnInit() {
    this.wizardService.paramsInformation.subscribe(
      (wizardParam: WizardParam) => {
        // TODO: this number (5) must be paramter by contant or something configuable
        if (this.wizardSteps.length < 5) {
          this.initBuildWizardSteps(wizardParam);
        }
      }
    );

    this.wizardService.updateCurrentStep.subscribe((newStep: NewStep) => {
      this.updateWizardState(this.wizardSteps[newStep.step]);
      this.router.navigate([
        this.wizardSteps[newStep.step].path,
        newStep.transaction,
      ]);
    });

    this.wizardService.reloadNavigation.subscribe((stepNumber: number) => {
      this.reloadWizardCache(stepNumber);
    });

    this.wizardService.changeWizardSteps.subscribe((path: string) => {
      if (this.wizardSteps) {
        this.wizardSteps = this.wizardSteps.filter(
          (step) => step.path !== path
        );
        this.wizardSteps.forEach((step, i) => (step.stepNumber = i));

        const currentStepIndex = this.wizardSteps.findIndex((step) => {
          return step.path === this.router.url.split("/")[1];
        });
        if (this.wizardSteps[currentStepIndex]) {
          this.setInitialWizardConfiguration(
            this.wizardSteps[currentStepIndex]
          );
        }
      }
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        setTimeout(() => {
          if (
            this.paymentTransactionService.isAdminPlatform &&
            this.paymentTransactionService.firstLoad
          ) {
            sessionStorage.removeItem("wizardCache");
          }
          this.reloadWizardCache(this.getCurrentPath());
        });
      });

    this.subcriptionDeleteCoupon();

    const transaction = this.paymentTransactionService.getCacheTransaction();
    if (transaction) {
      if (transaction.payments.some((x) => x.coupon != null)) {
        this.wizardService.setExpirationDate(
          transaction.payments.find((x) => x.coupon != null).coupon
            .expirationDate
        );
      }
    }
  }

  subcriptionDeleteCoupon() {
    this.wizardService.getDeletecouponStatus().subscribe((status) => {
      if (status) {
        this.openModalDeleteCoupon();
      }
    });
  }

  openModalDeleteCoupon() {
    this.displayDeleteCoupon = true;
  }
  public closeModalDeleteCoupon(): void {
    this.displayDeleteCoupon = false;
  }

  private getCurrentPath() {
    if (
      this.paymentTransactionService.isAdminPlatform &&
      this.paymentTransactionService.firstLoad
    ) {
      let pathPos = this.wizardService.currentStep;
      if (pathPos === undefined) {
        pathPos = 1;
      }
      return this.router.url.split("/")[pathPos].split("?")[0];
    } else {
      return this.router.url.split("/")[1];
    }
  }

  private reloadWizardCache(currentPath): void {
    this.wizardSteps = JSON.parse(sessionStorage.getItem("wizardCache"));
    if (!this.wizardSteps) {
      this.wizardSteps = [];
      if (this.paymentTransactionService.isAdminPlatform) {
        this.addInitAdminPaymentStep();
      } else {
        this.addInitPaymentStep();
      }
      this.addReviewPaymentStep();
      this.addPaymentMethodStep();
      this.addConfirmPaymentStep();
      this.addConfirmedPaymentStep();
    }

    const currentStepIndex = this.wizardSteps.findIndex(
      (step) => step.path === currentPath
    );
    if (this.wizardSteps[currentStepIndex]) {
      this.setInitialWizardConfiguration(this.wizardSteps[currentStepIndex]);
    }
  }

  private initBuildWizardSteps(wizardParam) {
    this.clearWizzardSteps();
    if (wizardParam.transactionId) {
      this.reloadWizardCache(RoutePath.initPayment);
    } else {
      if (this.paymentTransactionService.isAdminPlatform) {
        this.addInitAdminPaymentStep();
      } else {
        this.addInitPaymentStep();
      }
      if (!wizardParam.urlParams.email || !wizardParam.urlParams.name) {
        this.addReviewPaymentStep();
      }
      this.addPaymentMethodStep();
      this.addConfirmPaymentStep();
      this.addConfirmedPaymentStep();
    }
    if (this.wizardSteps[0]) {
      this.setInitialWizardConfiguration(this.wizardSteps[0]);
    }
  }

  private updateWizardState(currentWizardStep: WizardStep) {
    if (currentWizardStep) {
      currentWizardStep.state = "active";
      this.wizardSteps.forEach((wizardStep: WizardStep) => {
        if (wizardStep.stepNumber < currentWizardStep.stepNumber) {
          wizardStep.state = "completed";
        }
        if (wizardStep.stepNumber > currentWizardStep.stepNumber) {
          wizardStep.state = null;
        }
      });
    }
  }

  private setInitialWizardConfiguration(initWizardstep) {
    sessionStorage.setItem("wizardCache", JSON.stringify(this.wizardSteps));
    this.wizardService.setInitialStep(initWizardstep, this.wizardSteps);
    this.updateWizardState(initWizardstep);
  }

  private addInitPaymentStep() {
    this.wizardSteps.push(
      new WizardStep(
        this.wizardSteps.length,
        "Ingresá tu código",
        RoutePath.initPayment
      )
    );
  }

  private addInitAdminPaymentStep() {
    this.wizardSteps.push(
      new WizardStep(
        this.wizardSteps.length,
        "Verificá la información",
        RoutePath.initAdminPayment
      )
    );
  }

  private addReviewPaymentStep() {
    this.wizardSteps.push(
      new WizardStep(
        this.wizardSteps.length,
        "Completá tus datos",
        RoutePath.reviewPayment
      )
    );
  }

  private addPaymentMethodStep() {
    this.wizardSteps.push(
      new WizardStep(
        this.wizardSteps.length,
        "Método de pago",
        RoutePath.paymentMethod
      )
    );
  }

  private addConfirmPaymentStep() {
    this.wizardSteps.push(
      new WizardStep(
        this.wizardSteps.length,
        "Confirmá el pago",
        RoutePath.confirmPayment
      )
    );
  }

  private addConfirmedPaymentStep() {
    this.wizardSteps.push(
      new WizardStep(
        this.wizardSteps.length,
        "Pago confirmado",
        RoutePath.resultMessage
      )
    );
  }

  private existExpiredPayments(payments: Payment[]): boolean {
    return payments.some((payment: Payment) => {
      const dueDate =
        payment.buildingExpense.buildingExpensePaymentStatus === 0
          ? payment.buildingExpense.dueDate1
          : payment.buildingExpense.dueDate2;
      return (
        new Date(dueDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
      );
    });
  }

  private clearWizzardSteps() {
    this.wizardSteps = [];
  }

  isAdminPlatform() {
    return this.paymentTransactionService.isAdminPlatform;
  }
}
