export enum PaymentMethod {
  none = 0,
  pei = 1,
  tridi = 2,
  payzen = 3,
  payzenDebit = 4,
  cash = 5,
  payzenCreditInstallments = 6,
}

export enum PaymentMethodEnable {
  "Ningúno",
  "Pago Electrónico",
  "Transferencia",
}
