export const endpoints = {
  "building-expenses": "/buildingExpenses",
  "registered-payments": "/payments",
  "payment-transaction": "/paymentTransactions",
  "update-payment-transaction": "/paymentTransactions/:transactionId",
  "remove-payment": "/paymentTransactions/:transactionId/payments/:id",
  "update-payments": "/paymentTransactions/:transactionId/payments",
  "pay-pei-transaction": "/paymentTransactions/:transactionId/pay",
  "pay-tridi-transaction": "/paymentTransactions/:transactionId/paytridi",
  "get-ticket": "/paymentTransactions/:transactionId/ticket",
  "payment-types": "/PaymentTypes",
  "unsubscribe-reasons": "/Reasons",
  "unsubscribe-email": "/User",
  "unsubscribe-requesteduser": "/RequestedUser",
  "max-amount": "/PeiLimit",
  "get-pei-hash": "/PayIntention",
  "change-pei-status": "/PayStatus",
  "invite-accession": "/Subscribe/:check",
  "invite-accession-valid": "/Subscribe",
  "invite-accession-receipt": "/Subscribe/receipt",
  "payment-transaction-admin": "/paymentTransactions/:transactionId/admin",
  "discount-coupon": "/Coupon/:transactionId",
  "decline-coupon": "/Coupon/:transactionId/decline",
  "apply-coupon": "/paymentTransactions/:transactionId/applyCoupon",
  "delete-coupon": "/paymentTransactions/:transactionId/deleteCoupon",
  "keep-alive-coupons": "/Coupon/:transactionId/keepAliveCoupons",
  "subscribe-commercial-messages": "/SubscribeCommercialMessage/Subscribe",
  "validate-subscription-email":
    "/SubscribeCommercialMessage/SubscriptionStatus",
  "unsubscribe-commercial-message": "/SubscribeCommercialMessage/Unsubscribe",
  "unsubscribe-requested": "/SubscribeCommercialMessage/UnsubscribeRequested",
  "get-receipts": "/DownloadReceipts/Receipts",
  "download-receipts": "/DownloadReceipts/Download",
  "receipt-file-name": "/DownloadReceipts/FileName",
  "get-payzen-form-token": "/Payzen/PayIntention/:transactionId",
  "get-payzen-installments-form-token":
    "/Payzen/PayIntention/:transactionId/creditInstallments",
  "get-payzen-env-debito": "/Payzen/GetEnvironmentDebit",
  "get-payzen-env-credito": "/Payzen/GetEnvironmentCredit",
  "get-payzen-env-credito-installments":
    "/Payzen/GetEnvironmentCreditInstallments",
  "save-name-and-document-number":
    "/Payzen/SaveNameAndDocumentNumber/:transactionId",
  "max-amount-payzen": "/Payzen/PayzenLimit",
  generateRefereedCoupon: "/Coupon/generateRefereedCoupon/:transactionId",
  getCodeRefereedCoupon: "/Coupon/getCodeRefereedCoupon/:transactionId",
  consumeRefereedCoupon: "/Coupon/consumeRefereedCoupon/:transactionId",
};
