///
/// If you make changes to this file you must also make changes to generic\src\app\shared\constants\shared.constant.ts
///
export const PROCESS_INFO_MESSAGE = 'La información de tu expensa se está procesando...';
export const BUILDING_EXPENSE_NOT_FOUND = 'La expensa ingresada no se encuentra disponible. Por favor comunicate con tu administrador para mayor información.';
export const BUILDING_EXPENSE_NOT_TRIDI_ENABLED = 'Por el momento este servicio no se encuentra disponible. Por favor comunicate con tu administrador para mayor información.';
export const URL_EXPENSAS_PAGAS = 'https://www.expensaspagas.com.ar/';
export const SITE_NAME = 'expensaspagas.online';

export const MAX_AMOUNT_REPLACE = 'MAX_AMOUNT';
export const PENDING_PAY = 'PENDING_PAY';

