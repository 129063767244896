import { ExpensesDetail } from "./expenses-detail.model";
import { PaymentMethod } from "../constants/payments-methods.constants";
import { PaymentStatus } from "../constants/expenses-payment-status.constant";
import { DateTimeUtils } from "@microsoft/applicationinsights-common";

export class TransactionPayload {
  ownerEmail?: string;
  ownerName?: string;
  payments: AddPayment[];
  // TODO: remove this attr and its initialization when the multiple paymentMetod be implement.
  paymentMethod: PaymentMethod;
  constructor() {
    this.ownerEmail = null;
    this.payments = [];
    this.paymentMethod = PaymentMethod.none;
  }
}

export class Transaction {
  id: string;
  amount: number;
  ownerEmail: string;
  ownerName: string;
  fullOwnerName: string;
  repeatEmail?: string;
  receiptNumber?: string;
  operationNumber?: string;
  isPaid: boolean;
  payments: Payment[];
  paymentMethod: PaymentMethod;
  isAdmin: boolean;
  creditCardInstallments: boolean;
  fakeReceiptNumber?: string;
  cvu?: string;
  constructor() {
    this.amount = 0;
    this.ownerEmail = "";
    this.ownerName = "";
    this.fullOwnerName = "";
    this.receiptNumber = "";
    this.payments = [];
    this.paymentMethod = PaymentMethod.none;
    this.isAdmin = false;
    this.creditCardInstallments = false;
    this.fakeReceiptNumber = "";
    this.cvu = "";
  }
}

export class Payment {
  id: string;
  amount: number;
  buildingExpense: ExpensesDetail;
  coupon: Coupon;
  constructor() {
    this.amount = 0;
    this.buildingExpense = new ExpensesDetail();
    this.coupon = new Coupon();
  }
}

export class Coupon {
  id: string;
  itemCode: string;
  amount: number;
  expirationDate: Date;
  minimumAmount: number;
  referredDtoAmount?: number;
  isProgramReferred: boolean;
  enabledDebitCard: boolean;
  enabledCreditCard: boolean;

  constructor() {
    this.amount = 0;
  }
}

export class EditPayment {
  id: string;
  amount: number;
  buildingExpenseId: string;
  constructor(payment: Payment) {
    this.amount = payment.amount;
    this.buildingExpenseId = payment.buildingExpense.id;
    this.id = payment.id;
  }
}

export class AddPayment {
  electronicPaymentCode: string;
  amount: number;
  buildingExpenseId: string;
  id: string;
  constructor(expense: ExpensesDetail, id?: string, amount?: number) {
    this.id = id;
    this.buildingExpenseId = expense.id;
    this.electronicPaymentCode = expense.electronicPaymentCode;
    this.amount = amount;
  }
}

export class PayPeiTransaction {
  cardholderName: string;
  identityNumber: string;
  cardNumber: string;
  expirationMonth: number;
  expirationYear: number;
  securityCode: string;
  amount: string;
  terms: boolean;
  constructor() {
    this.cardholderName = "";
    this.identityNumber = "";
    this.cardNumber = "";
    this.expirationMonth = 0;
    this.expirationYear = 0;
    this.securityCode = "";
    this.amount = "";
    this.terms = false;
  }
}

export class PayTridiTransaction {
  ownerIdentityNumber: string;
  terms: boolean;
  constructor() {
    this.ownerIdentityNumber = "";
    this.terms = false;
  }
}

export class PayPeiOperation {
  paymentTransactionId: string;
  statusPeiLink: string;
  operationId: string;
  bankReferenceNumber: string;
  constructor() {
    this.paymentTransactionId = "";
    this.statusPeiLink = "";
    this.operationId = "";
    this.bankReferenceNumber = "";
  }
}

export enum PayStatus {
  CARGANDO_FORMULARIO = 1,
  FORMULARIO_CARGADO,
  ERROR,
  PAGO_REALIZADO,
  PAGO_REALIZADO_NOTIFICACION_PENDIENTE,
  ERROR_EN_PAGO,
  ERROR_EN_PAGO_NOTIFICACION_PENDIENTE,
  CONTINUAR,
  VENTANA_CERRADA,
  INTENCION_SOLICITADA,
  OPERACION_CONCILIADA,
  PAGO_CANCELADO,
  PAGO_CANCELADO_NOTIFICACION_PENDIENTE,
  ACTIVA,
  VENCIDA,
}

export const PayStatusName = [
  "CARGANDO_FORMULARIO",
  "FORMULARIO_CARGADO",
  "ERROR",
  "PAGO_REALIZADO",
  "PAGO_REALIZADO_NOTIFICACION_PENDIENTE",
  "ERROR_EN_PAGO",
  "ERROR_EN_PAGO_NOTIFICACION_PENDIENTE",
  "CONTINUAR",
  "VENTANA_CERRADA",
  "INTENCION_SOLICITADA",
  "OPERACION_CONCILIADA",
  "PAGO_CANCELADO",
  "PAGO_CANCELADO_NOTIFICACION_PENDIENTE",
  "ACTIVA",
  "VENCIDA",
];

// export enum CouponStatus {
//   Activated = 0,
//   Paused = 1,
//   Drained = 2,
//   Expired = 3,
// }
