export const environment = {
  production: true,
  baseApiUrl: 'https://api.expensaspagas.online',
  appInsights: {
    instrumentationKey: 'a54025e5-2fd4-43dd-9d6b-0abb268a0086'
  },
  context: 'https://pei.redlink.com.ar',
  portalId:2,
  title:'Expensas Pagas <strong>Online</strong>',
  isTestMode: false
};
