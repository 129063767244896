export const TERMS_CONDITIONS = `<p><strong>TERMINOS Y CONDICIONES GENERALES DE USO</strong></p>
<p><span >Los presentes t&eacute;rminos y condiciones generales de uso constituyen las reglas aplicables a toda persona (en adelante "</span><span >Usuario</span><span >" o en plural "</span><span >Usuarios</span><span >") que acceda al sitio </span><span ><a href="//www.expensaspagas.online" target="_blank">www.expensaspagas.online</a> </span><span >&nbsp;(en adelante "</span><span >Sitio</span><span >" o &ldquo;</span><span >Portal</span><span >&rdquo;). </span></p>
<strong>1. Usuario</strong>
<p><span >El mero acceso al Sitio atribuye a quien lo haga la condici&oacute;n de Usuario del mismo e implica la aceptaci&oacute;n plena y sin reserva de todos los t&eacute;rminos y condiciones que se encuentren all&iacute; publicadas (en adelante &ldquo;</span><span >T&eacute;rminos y Condiciones</span><span >&rdquo;). </span><span >S&oacute;lo podr&aacute;n ser Usuarios las personas que tengan capacidad legal para contratar.</span></p>
<strong>2. Modificaciones</strong>
<p><span >La Empresa se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones respecto a la informaci&oacute;n contenida en el Sitio, as&iacute; como sus T&eacute;rminos y Condiciones. Las modificaciones que se hagan surtir&aacute;n efecto respecto de todos los Usuarios en forma inmediata a su incorporaci&oacute;n al Sitio.</span></p>
<p><strong>CUALQUIER PERSONA QUE NO ACEPTE ESTOS T&Eacute;RMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN CAR&Aacute;CTER OBLIGATORIO Y VINCULANTE, DEBER&Aacute; ABSTENERSE DE UTILIZAR EL SITIO.</strong></p>
<strong>3. Definiciones</strong>
<p><span >Todas las palabras que se utilicen con may&uacute;scula en los presentes T&eacute;rminos y Condiciones, tendr&aacute;n el significado que se les asigne a continuaci&oacute;n. </span></p>
<p><span >&ldquo;Administrador&rdquo;</span><span >: Ser&aacute; quien contrate los Servicios en representaci&oacute;n de un Consorcio. Adem&aacute;s de ser Usuario del Sitio, con derecho a una clave de acceso a la Intranet. &nbsp;</span></p>
<p><span >&ldquo;</span><span >Agentes de Cobro</span><span >&rdquo;: son las entidades bancarias y no bancarias habilitadas para operar en la Rep&uacute;blica Argentina, integrantes de la red de agentes cobradores habilitados por la Empresa (ej.: Link Pagos, Pago Mis Cuentas, etc.) o el propio Portal de Pagos de Plataforma de Pagos S.A. Los Agentes de Cobro son terceros proveedores de la Empresa y en virtud de un acuerdo con la misma, los Propietarios realizan los pagos de las Expensas con los CPEs y otros c&oacute;digos de identificaci&oacute;n homologados por la Empresa, sea por redes electr&oacute;nicas, el Portal de Pagos, etc. Los pagos as&iacute; realizados son transferidos a una Cuenta Recaudadora. La Empresa informa al Administrador los Agentes de Cobro habilitados para el cobro de las Expensas. Atento que los mismos dependen de acuerdos arribados entre la Empresa y las entidades de cobro, los Agentes de Cobro pueden variar seg&uacute;n los Consorcios y seg&uacute;n los acuerdos de servicios con Administrador. Durante la vigencia del contrato de Servicios la Empresa informar&aacute; regularmente al Administrador respecto de las entidades que sean incluidas o excluidas para actuar como Agente de Cobro. &nbsp;</span></p>
<p><span >&ldquo;Clave de Ingreso&rdquo;</span><span >: es el usuario y clave asignado a cada Usuario para operar en las &aacute;reas restringidas del Sitio. La Clave de Ingreso es personal, &uacute;nica e intransferible. </span></p>
<p><span >&ldquo;Clave de Pago &ldquo;CPE&rdquo;, C&oacute;digos de Barras, QR y otros</span><span >: los c&oacute;digos que el Administrador a trav&eacute;s de su sistema de liquidaci&oacute;n, y excepcionalmente -en ausencia de un sistema de liquidaci&oacute;n- Expensas Pagas, asignan a las Expensas de un Consorcio que corresponde abonar por cada unidad funcional, para que cada Propietario pueda abonar su Expensa en los distintos medios de pagos electr&oacute;nicos habilitados (Agentes de Cobro), son los que permiten identificaci&oacute;n y conciliaci&oacute;n del pago. &nbsp;</span></p>
<p><span >&ldquo;</span><span >Consorcio</span><span >&rdquo;: es la persona jur&iacute;dica constituida por el conjunto de propietarios de las unidades funcionales de un inmueble afectado a propiedad horizontal (art. 2044 CCCN); y otras formas jur&iacute;dicas tales como S.A, S.R.L., Fideicomisos, Asociaciones Civiles y otras, siendo descripci&oacute;n meramente enunciativa.</span></p> 
<p><span >&ldquo;</span><span >Cuenta Recaudadora</span><span >&rdquo;: es la cuenta bancaria abierta a nombre de la Empresa en los Bancos recaudadores receptores de los cobros de Expensas realizados a trav&eacute;s de los Agentes de Cobro y cuyo destino es la transferencia a las cuentas bancarias indicada por cada Administrador, seg&uacute;n plazos previstos de los contratos de servicios. &nbsp;</span></p>
<p><span >&ldquo;Empresa&rdquo; o &ldquo;PLAPSA&rdquo;</span><span >: ser&aacute; Plataforma de Pagos SA.</span></p>
<p><span >&ldquo;Expensas&rdquo;</span><span >: son los montos que liquidan los Administradores &ndash;mediante la utilizaci&oacute;n de un sistema propio o de terceros- relativo a los gastos comunes ordinarios y extraordinarios del Consorcio. Los montos liquidados por el Administrador son asignados entre los propietarios de las unidades funcionales conforme a porcentajes de participaci&oacute;n que determina el Administrador en base al Reglamento de cada Consorcio. Cada Administrador informa a la Empresa los montos que corresponde pagar a cada uno de los propietarios de las unidades funcionales que componen el Consorcio. Los Administradores a trav&eacute;s de sus sistemas de liquidaci&oacute;n generan las boletas de pagos con los respectivos C&oacute;digos de Barras, Electr&oacute;nicos y otros; que hubiesen sido homologados ante la Empresa, en cumplimiento de las especificaciones t&eacute;cnicas de seguridad inform&aacute;tica. La Empresa publica -los montos de las Expensas suministradas por Administrador- en los distintos medios de pagos electr&oacute;nicos de los Agentes de Cobro, para que los Propietarios puedan realizar el pago. &nbsp;</span></p>
<p><span >&ldquo;Informaci&oacute;n&rdquo;</span><span >: se refiere a la informaci&oacute;n que recibe la Empresa de los Administradores, en forma directa o por intermedio de otras empresas que proporcionen sistemas de liquidaci&oacute;n de expensas. Asimismo se denominar&aacute; as&iacute; a la informaci&oacute;n que proporciona la Empresa a terceros y Usuarios, sea que se encuentre publicada en el Sitio, sea que se acceda mediante inicio de sesi&oacute;n al Portal de Pagos, o sea informaci&oacute;n contenida en la Intranet. Tambi&eacute;n se considera Informaci&oacute;n al contenido de los Reportes que en forma electr&oacute;nica pueda enviar peri&oacute;dicamente la Empresa a los Administradores.</span></p>
<p><span >&ldquo;Intranet&rdquo;</span><span >: es un &aacute;rea del Sitio de acceso restringido y uso exclusivo de los Administradores que tengan un contrato de prestaci&oacute;n de Servicios vigente con la Empresa. La misma contendr&aacute; Informaci&oacute;n relativa a los pagos de Expensas realizados por Propietarios mediante los Agentes de Cobro, informe de rendiciones diarias, informe de las transferencias bancarias y/o dep&oacute;sitos a la cuenta bancaria indicada por el Administrador, as&iacute; como reportes sobre los importes cobrados, cantidad de pagos recibidos, comisiones cobradas y montos netos transferidos y/o depositados.</span></p>
<p><span >&ldquo;Portal de Pagos &rdquo;</span><span > o </span><span >&ldquo;Expensas Pagas Online&rdquo;</span><span >: es un &aacute;rea del Sitio cuyo uso se encuentra restringido a los Propietarios y tiene por finalidad publicar las Expensas para su pago mediante el uso de tarjetas de d&eacute;bito, CBU, Alias y otros o cualquier otro medio de pago habilitado o que se habilite en el futuro en el sitio </span><a href="http://www.expensaspagas.com.ar" target="_blank"><span >www.expensaspagas.com.ar</span></a><span >. El Propietario podr&aacute; acceder a Expensas Pagas Online mediante ingreso al sitio antes mencionado o mediante un email u otros datos identificatorios que permitan el acceso, que le notifica la disponibilidad de una Expensa y lo invita a pagar a trav&eacute;s de dicho sitio. En el sitio </span><a href="http://www.expensaspagas.com.ar" target="_blank"><span >www.expensaspagas.com.ar</span></a><span > el Propietario podr&aacute; visualizar la Expensa, datos identificatorios, fecha de vencimiento para el pago y el importe a pagar. El Propietario podr&aacute; confirmar el importe a pagar o modificar el importe (sea para realizar pagos parciales o adelanto de pagos), y para proceder al pago deber&aacute; incluir sus datos personales y los datos de la tarjeta de d&eacute;bito, CBU, Alias y otros con la que se realizar&aacute; el pago. Una vez realizado el pago el Propietario recibir&aacute; un mensaje en pantalla y un correo electr&oacute;nico, ambos confirmando el pago. El Portal podr&aacute; brindar otras funciones complementarias, tales como: Ver el perfil de cuenta, Consultar el historial de los &uacute;ltimos pagos realizados, Descargar e imprimir comprobante de pagos realizados. </span></p>
<p><span >&ldquo;Propietarios&rdquo;</span><span >: son los Usuarios que est&eacute;n autorizados por un Administrador para operar Expensas Pagas Online, o que realicen el pago de Expensas a trav&eacute;s de los Agentes de Cobro. El t&eacute;rmino &ldquo;Propietarios&rdquo; no se utiliza con el alcance jur&iacute;dico del t&eacute;rmino e incluye tambi&eacute;n a inquilinos, cond&oacute;minos, comodatarios, ocupantes o cualquier tercero que mediante el uso de una Clave de Ingreso inicie una sesi&oacute;n en Portal de Pagos con la finalidad de realizar el pago de una o m&aacute;s Expensas o realice pagos de CPEs mediante los restantes Agentes de Cobro. &nbsp;</span></p>
<p><span >&ldquo;Reportes&rdquo;</span><span >: son los res&uacute;menes de Informaci&oacute;n que la Empresa enviar&aacute; diariamente a los Administradores con las novedades relativas a los pagos recibidos discriminando por Consorcio y unidad funcional. Asimismo env&iacute;a un Reporte con los informes de las &oacute;rdenes de transferencias realizadas diariamente desde la Cuenta Recaudadora a la cuenta bancaria que haya indicado el Administrador. </span></p>
<p><span >&ldquo;Servicios&rdquo;</span><span >: La Empresa presta el servicio de recaudaci&oacute;n de Expensas por cuenta y orden de terceros en el proceso de pago de las Expensas de cada Consorcio, habilitando un abanico de Agentes de Cobro para facilitar el pago y centralizando el cobro en una Cuenta Recaudadora. Ello se completa con la identificaci&oacute;n, el registro de cada uno de los pagos y su conciliaci&oacute;n, todo lo cual es comunicado mediante el env&iacute;o de Informaci&oacute;n y la transferencia y/o dep&oacute;sito de fondos a la cuenta bancaria indicada por el Administrador para cada Consorcio. </span></p>
<strong>4. Deslinde de Responsabilidad:</strong>
<p><span >En la presente cl&aacute;usula, el t&eacute;rmino Empresa incluir&aacute; a sus directores, gerentes, representantes, apoderados, empleados, contratistas, agentes y operarios en lo que refiere al deslinde de responsabilidad por parte de los Usuarios. &nbsp;</span></p>
<p><span >4.1.</span><span >&nbsp;&nbsp;&nbsp; </span><span >Funcionamiento del Portal </span></p>
<p><span >La Empresa no garantiza la disponibilidad y continuidad del funcionamiento del Portal, los Servicios, ni de los medios de cobro administrados por los Agentes de Cobro. En consecuencia, el Administrador se compromete a mantener habilitada una forma de pago de Expensas alternativa a la prevista en el Servicio que ofrece la Empresa.</span></p>
<p><span >El Usuario renuncia a realizar reclamo alguno a la Empresa por el uso, mal uso o ejecuci&oacute;n de los Servicios, con el retraso o falta de disponibilidad de uso, actualizaci&oacute;n o desactualizaci&oacute;n de la Informaci&oacute;n, alteraci&oacute;n, error, omisi&oacute;n, p&eacute;rdida, da&ntilde;o o modificaci&oacute;n total o parcial de la Informaci&oacute;n o contenido del Sitio. En consecuencia, la Empresa no ser&aacute; responsable por los da&ntilde;os emergentes y/o perjuicios (lucro cesante) sufridos por el o los Usuarios por las ocurrencia de las situaciones antes descriptas. </span></p>
<p><span >Tampoco ser&aacute; responsable la Empresa de cualquier da&ntilde;o o perjuicio en el software o hardware de los Usuario, que sea una consecuencia directa o indirecta del acceso al Sitio, o del uso del Portal de Pago SA, la Intranet, o links contenidos en el Sitio que re-direccionen a otros portales, ni los que se puedan derivar de interferencias, omisiones, interrupciones, virus inform&aacute;ticos o desconexiones del Portal, caso fortuito, fuerza mayor.</span></p>
<p><span >4.2.</span><span >&nbsp;&nbsp;&nbsp; </span><span >Uso de la Informaci&oacute;n</span></p>
<p><span >El Administrador, el Consorcio y el Usuario en forma solidaria e ilimitada asumir&aacute;n ante la Empresa toda responsabilidad derivada de la interpretaci&oacute;n y el uso de la Informaci&oacute;n contenida en el Portal. </span></p>
<p><span >La Empresa se reserva el derecho de guardar y utilizar como prueba de las operaciones y/o transacciones realizadas por los Usuarios, los registros electr&oacute;nicos que se generan una vez que el Administrador y/o Propietario inicien una sesi&oacute;n mediante el uso de su Clave de Ingreso.</span></p>
<p><span >4.3.</span><span >&nbsp;&nbsp;&nbsp; </span><span >Ileg&iacute;tima Intervenci&oacute;n de Terceros</span></p>
<p><span >El Usuario acepta conocer los riesgos que conlleva este tipo de transacciones y exoneran expresamente a la Empresa de toda responsabilidad por la ileg&iacute;tima intervenci&oacute;n de otros Usuarios o terceros en el Sitio que pudiera ocasionarle la violaci&oacute;n de derechos patrimoniales y/o personales, declamando conocer que la Empresa ha realizado sus mejores esfuerzos t&eacute;cnicos a los efectos de que el acceso a &aacute;reas restringidas del Sitio mantenga el m&aacute;s alto grado de confidencialidad posible.</span></p>
<p><span >4.4.</span><span >&nbsp;&nbsp;&nbsp; </span><span >Veracidad de la Informaci&oacute;n</span></p>
<p><span >La Empresa no se hace responsable por la veracidad de la Informaci&oacute;n declarada por los Usuarios o provista por los Administradores, Propietarios, Usuarios ni Agentes de Cobro. </span></p>
<p><span >Los Usuarios reconocen que la Empresa es una intermediaria en la gesti&oacute;n de cobro y sus Servicios no involucran intromisi&oacute;n alguna en la administraci&oacute;n de las cuentas del Consorcio. Por lo tanto, la Empresa no efect&uacute;a juicios de valor de la Informaci&oacute;n provista por el Administrador, ni tareas de control de las causas, importes, calidad cantidad, funcionamiento, estado, integridad de los bienes y servicios considerados en la liquidaci&oacute;n de Expensas, ni realiza la verificaci&oacute;n y/o auditor&iacute;a sobre la liquidaci&oacute;n de las Expensas informadas por el Administrador, ni respecto de la distribuci&oacute;n y asignaci&oacute;n que &eacute;ste realice de las Expensas entre los propietarios de las unidades funcionales que componen cada Consorcio. </span></p>
<p><span >La Empresa gestiona el cobro de las Expensas y consolida los pagos en base a Informaci&oacute;n provista por el Administrador del Consorcio. Del mismo modo, la Empresa no se responsabiliza por el error en la informaci&oacute;n provista por los Agentes de Cobro.</span></p>
<p><span >4.5.</span><span >&nbsp;&nbsp;&nbsp; </span><span >Exclusi&oacute;n Parcial o Total &ndash; Transitoria o Permanente de Usuarios</span></p>
<p><span >La Empresa se reserva el derecho de rechazar una solicitud de inicio de sesi&oacute;n o de cancelar o suspender, temporal o definitivamente el acceso a uno o m&aacute;s &aacute;reas del Sitio a uno o m&aacute;s Usuarios en caso de detectar &ndash;a su exclusivo criterio- incongruencias o inconsistencias en la Informaci&oacute;n provista por un Usuario o en caso de detectar &ndash;tambi&eacute;n a su exclusivo criterio- actividades sospechosas, sin que tal decisi&oacute;n genere para ning&uacute;n Usuario derechos de indemnizaci&oacute;n o resarcimiento contra la Empresa.</span></p>
<p><span >4.6.</span><span >&nbsp;&nbsp;&nbsp; </span><span >Insolvencia del Agente de Cobro &ndash; Cambio Legal o Regulatorio</span></p>
<p><span >Atento que los Agentes de Cobro con lo que contrata la Empresa son las principales entidades del sistema financiero, la Empresa no ser&aacute; responsable en ning&uacute;n caso por la insolvencia del banco, entidad financiera o agente utilizado para la transferencia de los fondos o cualquier cambio legal o regulatorio que afecte la Cuenta Recaudadora en la cual los fondos sean depositados. </span></p>
<p><span >Los Usuarios eximen de responsabilidad a la Empresa por situaciones que pudieran afectar a las entidades bancarias, financieras y/o agentes donde est&aacute;n depositados los fondos de la Cuenta Recaudadora, como por situaciones pol&iacute;ticas y econ&oacute;micas que pudieran presentarse en el pa&iacute;s, y que sean ajenas a la Empresa. En estos casos, los Usuarios no podr&aacute;n imputarle responsabilidad alguna a la Empresa, ni exigir el reintegro del dinero o pago por lucro cesante, en virtud de perjuicios resultantes de este tipo de situaciones ni por pagos no procesados por el sistema o rechazados, cuentas suspendidas o canceladas.</span></p>
<p><span >4.7</span><span >&nbsp;&nbsp;&nbsp; </span><span >L&iacute;mite de Responsabilidad</span></p>
<p><span >Sin perjuicio de lo expuesto con respecto a la declaraci&oacute;n de deslinde de responsabilidad de los Usuarios a la Empresa, y para el caso que no obstante ello la Empresa pudiera ser considerada responsable frente al Usuario, se admite que el monto m&aacute;ximo de indemnizaci&oacute;n al que podr&aacute; estar sujeta la Empresa no podr&aacute; superar el valor resultante del monto de la transacci&oacute;n involucrada en forma directa en la producci&oacute;n del da&ntilde;o achacado a la Empresa. </span></p>
<p><span >En ning&uacute;n caso la Empresa ser&aacute; responsable por el lucro cesante, p&eacute;rdida de chance, p&eacute;rdida de uso, p&eacute;rdida de producci&oacute;n, p&eacute;rdida de contratos, p&eacute;rdida de ahorros, y en general por ning&uacute;n otro da&ntilde;o o p&eacute;rdida indirecta o consecuencial que pudiera ser sufrida por el Usuario, por la utilizaci&oacute;n de los Servicios que brinda la Empresa y por las transacciones realizadas o no realizadas a trav&eacute;s de los Agentes de Cobro. </span></p>
<strong>5. Obligaciones de los Usuarios</strong>
<p><span >5.1.</span><span >&nbsp;&nbsp;&nbsp; </span><span >Clave de Ingreso</span></p>
<p><span >Para el ingreso a ciertas &aacute;reas del Sitio, la Empresa proveer&aacute; a los Usuarios una Clave de Ingreso. El Usuario se obliga a mantener la confidencialidad de su Clave de Ingreso. En virtud de ello, el Usuario ser&aacute; el &uacute;nico y exclusivo responsable por todas las operaciones efectuadas mediante el uso de dicha Clave de Ingreso.</span></p>
<p><span >El Usuario se compromete a notificar a la Empresa en forma inmediata y por medio id&oacute;neo y fehaciente, de cualquier uso no autorizado de su Clave de Ingreso, as&iacute; como del ingreso o de intentos de ingreso por terceros no autorizados a la misma.</span></p>
<p><span >5.2.</span><span >&nbsp;&nbsp;&nbsp; </span><span >Datos Personales</span></p>
<p><span >El Usuario se compromete a mantener actualizados los datos personales que ingrese al Sitio, en particular su direcci&oacute;n de correo electr&oacute;nico y su car&aacute;cter de Propietario. </span></p>
<p><span >5.3.</span><span >&nbsp;&nbsp;&nbsp; </span><span >Uso Leg&iacute;timo </span></p>
<p><span >El Usuario utilizar&aacute; el Portal para las finalidades licitas para las cuales est&aacute; disponible. Por lo tanto deber&aacute; abstenerse de realizar, directa o indirectamente, las siguientes conductas:</span></p>
<ul>
<li ><span >Las que pudiera impedir o da&ntilde;ar los derechos de propiedad intelectual de la Empresa sobre el Portal</span></li>
<li ><span >Alterar o de cualquier modo da&ntilde;ar el contenido del Portal, uso del Expensas Pagas Online, la Intranet, o links contenidos en el Sitio, salvo por la Informaci&oacute;n que leg&iacute;timamente incorpore al Sitio. </span></li>
<li ><span >Aquellas que pudiera causar un da&ntilde;o a la imagen, prestigio, cr&eacute;dito mercantil o reconocimiento de la Empresa. </span></li>
<li ><span >Ingresar informaci&oacute;n falsa respecto de su identidad, tarjetas bancarias que desee agregar a los Agentes de Cobro.</span></li>
</ul>
<p><span >5.4.</span><span >&nbsp;&nbsp;&nbsp; </span><span >Inconsistencias</span></p>
<p><span >En caso de surgir inconsistencias respecto de un pago realizado por un Usuario y la informaci&oacute;n publicada en el Sitio o dada a conocer por el Administrador, ser&aacute; obligaci&oacute;n del Usuario realizar el reclamo correspondiente a la Empresa y al Administrador mediante medios fehacientes, as&iacute; como de aportar todas aquellas pruebas que se encuentren en su poder respecto del pago cuya inconsistencia se suscite. </span></p>
<p><span >Las anulaciones o devoluciones que correspondan ser&aacute;n juzgadas por la Empresa y se realizar&aacute;n en los plazos y en las formas que &eacute;sta lo disponga. </span></p>
<p><span >5.5.</span><span >&nbsp;&nbsp;&nbsp; </span><span >Cancelaci&oacute;n de Cuenta</span></p>
<p><span >El Usuario podr&aacute; en cualquier momento solicitar a la Empresa la cancelaci&oacute;n y cierre de su cuenta. Luego de la cancelaci&oacute;n de la cuenta se rechazar&aacute; cualquier transacci&oacute;n en tr&aacute;mite a menos que correspondiera su finalizaci&oacute;n. </span></p>
<strong>6. Autorizaci&oacute;n del Consorcio</strong>
<p><span >La contrataci&oacute;n de los Servicios por parte de un Administrador implica otorgar por cuenta y orden del Consorcio una autorizaci&oacute;n a favor de la Empresa, o a los Agentes de Cobro, seg&uacute;n sea el caso, para recibir, cobrar en la Cuenta Recaudadora para luego transferir o depositar y acreditar en la cuenta bancaria indicada por el Administrador los fondos provenientes del pago de las Expensas. </span></p>
<p><span >La Empresa se reserva el derecho de no admitir aquellos cobros cuyos montos difieran respecto de los indicados en los CPEs y por ende con los montos informados por el Administrador en la liquidaci&oacute;n de Expensas o en las cuales haya discrepancias entre el monto que corresponde seg&uacute;n el Propietario y los datos provistos por el Administrador. </span></p>
<strong>7. Divergencias </strong>
<p><span >Cualquier divergencia que pudiera surgir entre los Usuarios, Propietarios y el Administrador respecto de la liquidaci&oacute;n de Expensas, asignaci&oacute;n de porcentuales de participaci&oacute;n entre los propietarios de unidades funcionales, el destino de los fondos cuyo cobro haya sido gestionado por la Empresa, o cualquier otro motivo, sea judicial o extrajudicial, deber&aacute; ser zanjada sin involucrar a la Empresa, y sin interferir en los Servicios por esta prestados al Administrador del Consorcio en cuesti&oacute;n o a otros Administradores. Una vez que Propietarios y Administrador hayan arribado a un acuerdo sobre la divergencia, el Administrador lo comunicar&aacute; a la Empresa. </span></p>
<p><span >Los usuarios declaran conocer que la cuenta recaudadora a nombre de la empresa contiene fondos de otros usuarios incluso ajenos a su consorcio. Por este motivo, la aceptaci&oacute;n de los presente t&eacute;rminos y condiciones por parte de los usuarios conlleva la renuncia incondicional expresa e irrenunciable a solicitar el embargo de la cuenta recaudadora. La violaci&oacute;n a la presente clausula har&aacute; responsable al usuario que lo solicite de todos los da&ntilde;os y perjuicios que pudiera ocasionar a la empresa y a los restantes usuarios del sitio.</span></p>
<strong>8. Comunicaciones - Notificaciones</strong>
<p><span >Cualquier comunicaci&oacute;n o notificaci&oacute;n entre el Usuario y la Empresa ser&aacute; realizado por intermedio del Administrador. En consecuencia, la Empresa s&oacute;lo recibir&aacute; comunicaciones o notificaciones de los Usuarios cuando las mismas sean remitidas por la Administraci&oacute;n al domicilio postal o electr&oacute;nico que haya establecido a tal fin en los t&eacute;rminos y condiciones de prestaci&oacute;n del servicio que form&oacute; parte de la oferta. Del mismo modo, toda comunicaci&oacute;n que la Empresa debiera realizar por cualquier causa a un Usuario, lo har&aacute; notificando al Administrador al domicilio postal o electr&oacute;nico por &eacute;l constituido a los fines del contrato. </span></p>
<strong>9. Cancelaci&oacute;n &ndash; Anulaci&oacute;n &ndash; Contracargo - Reversi&oacute;n &ndash; Desconocimiento - &nbsp;</strong>
<p><span >Si una vez que la Empresa hubo realizado la transferencia de fondos correspondiente al pago de Expensas de un Usuario de la Cuenta Recaudadora a la cuenta bancaria indicada por el Administrador, se tomara conocimiento de que dicho pago ha sido cancelado o anulado o se ha realizado un contracargo, desconocimiento o reversi&oacute;n de dichos fondos, entonces la Empresa podr&aacute; recuperar los fondos mediante compensaci&oacute;n de fondos que se hubo acreditado en favor del mismo Administrador, o podr&aacute; descontar y debitar de la cuenta bancaria del Administrador. Si el Administrador no tuviera fondos en la Cuenta Recaudadora ni en la cuenta bancaria informada, el Administrador se obliga a reembolsar a la Empresa los fondos involucrados dentro del plazo de 15 d&iacute;as de notificado. Todos los Usuarios del Consorcio y el Consorcio son solidaria e ilimitadamente responsables con el Administrador por la deuda generada por el acontecimiento aqu&iacute; detallado. </span></p>
<strong>10. Imposibilidad de Transferir Fondos</strong>
<p><span >Si al momento en que la Empresa se apresta realizar la transferencia y dep&oacute;sito de fondos en la cuenta bancaria indicada por el Administrador, &eacute;sta se encontrara inhabilitada, fuere inexistente o por cualquier motivo no imputable a la Empresa o a la entidad bancaria donde se encuentra la Cuenta Recaudadora, fuere imposible realizar la transferencia o dep&oacute;sito de los fondos, &eacute;stos permanecer&aacute;n en la Cuenta Recaudadora hasta tanto el Administrador indique otra cuenta o solucione el impedimento. Durante el tiempo que los fondos permanezcan en la Cuenta Recaudadora no generar intereses en favor del Consorcio, Administrador o titular. </span></p>
<strong>11. Derecho de Compensaci&oacute;n</strong>
<p><span >El Administrador, autoriza por el presente a la Empresa a compensar de la Cuenta Recaudadora los pagos devengados por los Servicios prestados a cada Consorcio, as&iacute; como cualquier otro cr&eacute;dito al que pudiera tener derecho como consecuencia de la prestaci&oacute;n de los Servicios, de los fondos transferidos al Administrador por supuestos pagos de Expensas que fueran cancelados, anulados o respecto de los cuales se hubiera realizado un contracargo, desconocimiento o reversi&oacute;n. La Empresa podr&aacute; compensar tambi&eacute;n los nuevos costos o impuestos que pudieren imponerse en el futuro para las transferencias o dep&oacute;sitos en Cuentas Recaudadoras.</span></p>
<strong>12. Ley Aplicable &ndash; Jurisdicci&oacute;n</strong>
<p><span >Los presentes T&eacute;rminos y Condiciones Generales de Uso se rigen por las leyes de la Rep&uacute;blica Argentina. Asimismo, los pagos que se realicen mediante el portal de pagos Expensas Pagas Online se encuentran sujetos a las normas del BCRA. </span></p>
<p><span >En caso de disputas sobre la interpretaci&oacute;n, sentido y alcance de &eacute;stos T&eacute;rminos y Condiciones Generales de Uso los Usuarios se someten a los tribunales ordinarios de la Ciudad Aut&oacute;noma de Buenos Aires con renuncia expresa de cualquier otro fuero que les pudiera corresponder. &nbsp;</span></p>
<strong>13. Pol&iacute;ticas de Privacidad y Confidencialidad de la Informaci&oacute;n </strong>
<p><span >13.1.</span><span >&nbsp;&nbsp;&nbsp; </span><span >Introducci&oacute;n</span></p>
<p><span >Estas Pol&iacute;ticas de Privacidad y Confidencialidad de la Informaci&oacute;n (en adelante &ldquo;</span><span >Pol&iacute;ticas</span><span >&rdquo;) son parte integrante de los&nbsp;T&eacute;rminos y Condiciones Generales de Uso, y la aceptaci&oacute;n de &eacute;stos implica la aceptaci&oacute;n de las Pol&iacute;ticas aqu&iacute; contenidas.</span></p>
<p><span >Las Pol&iacute;ticas describen la informaci&oacute;n que la Empresa recoge sobre los Usuarios (en adelante &ldquo;</span><span >Datos</span><span >&rdquo;) sea que le sean provistos por el Administrador, sea que los recopile en el curso de la prestaci&oacute;n de los Servicios o que sean registrados a partir que el Usuario inicie una sesi&oacute;n en el Sitio. La aceptaci&oacute;n del Usuario de las Pol&iacute;ticas conlleva el consentimiento expreso para que la Empresa utilice sus Datos personales de acuerdo a las Pol&iacute;ticas que se describen a continuaci&oacute;n. Los Datos podr&aacute;n ser utilizados por la Empresa para procesar sus pedidos, solicitudes, denuncias, reclamos, as&iacute; como para fines derivados de la relaci&oacute;n comercial y fines publicitarios. </span></p>
<p><span >Disposici&oacute;n DNPDP 10/2008: &ldquo;</span><em><span >El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un inter&eacute;s leg&iacute;timo al efecto conforme lo establecido en el art&iacute;culo 14, inciso 3 de la Ley N&ordm; 25.326 y la Direcci&oacute;n Nacional de Protecci&oacute;n de Datos Personales, &Oacute;rgano de Control de la Ley N&ordm; 25.326, tiene la atribuci&oacute;n de atender las denuncias reclamos que se interpongan con relaci&oacute;n al incumplimiento de las normas sobre protecci&oacute;n de datos personales</span></em><span >&rdquo;</span></p>
<p><span >Direcci&oacute;n Nacional de Datos Personales </span></p>
<p><span >Sarmiento 1118, piso 5&ordm; (C1041AAX)</span></p>
<p><span >Tel: 4383-8510/12/13/15</span></p>
<p><a href="http://www.jus.gov.ar/datospersonales" target="_blank"><span >www.jus.gov.ar/datospersonales</span></a></p>
<p><a href="mailto:infodnpdp@jus.gov.ar"><span >infodnpdp@jus.gov.ar</span></a></p>
<p><span >13.2.</span><span >&nbsp;&nbsp;&nbsp; </span><span >Nuestro Compromiso con la Privacidad</span></p>
<p><span >La Empresa es una sociedad dedicada exclusivamente a la gesti&oacute;n, centralizaci&oacute;n y conciliaci&oacute;n de cobranzas. Esto conlleva m&uacute;ltiples Servicios vinculados al procesamiento de datos y es por esto que La Empresa respeta la privacidad de toda persona que visite el Sitio. &nbsp;</span><span >&nbsp;&nbsp;&nbsp; </span></p>
<p><span >Esta Pol&iacute;tica de Privacidad indica la informaci&oacute;n que la Empresa puede recopilar y el uso que puede dar a esa informaci&oacute;n (en adelante, los &ldquo;Datos&rdquo;).</span></p>
<p><span >Principios</span></p>
<p><span >Los Datos personales que se recojan a los efectos de su tratamiento ser&aacute;n ciertos, adecuados, pertinentes y no excesivos en relaci&oacute;n al &aacute;mbito y finalidad para los que se obtuvieron. La recolecci&oacute;n de datos no se efect&uacute;a por medios desleales, fraudulentos o en forma contraria a las disposiciones de la Normativa de Protecci&oacute;n de los Datos Personales. Los Datos objeto de tratamiento no ser&aacute;n utilizados para finalidades distintas o incompatibles con aquellas que motivaron su obtenci&oacute;n.</span></p>
<p><span >Protegiendo su Informaci&oacute;n</span></p>
<p><span >Para prevenir acceso no autorizado, mantener la precisi&oacute;n de los Datos y asegurar el uso correcto de la informaci&oacute;n, la Empresa ha dispuesto medios f&iacute;sicos, electr&oacute;nicos, administrativos y otros procedimientos de seguridad para resguardar y asegurar la informaci&oacute;n que recopilamos en l&iacute;nea. Nosotros resguardamos la informaci&oacute;n de acuerdo a est&aacute;ndares y procedimientos de seguridad establecidos y continuamente evaluamos nueva tecnolog&iacute;a para proteger su informaci&oacute;n. La Empresa garantiza que los procesos internos relativos a las distintas bases de datos cumplen con las obligaciones legales, de seguridad y de confidencialidad impuestas por la Ley de Protecci&oacute;n de Datos Personales N.&ordm; 25.326.</span></p>
<p><span >Sin embargo, Usted reconoce que los medios t&eacute;cnicos existentes que brindan seguridad no son inexpugnables, y que aun cuando se adopten todos los recaudos razonables es posible sufrir manipulaciones, destrucci&oacute;n y/o p&eacute;rdida de informaci&oacute;n.</span></p>
<p><span >Es por esto que, mientras intentamos proteger sus Datos, Usted tambi&eacute;n deber&aacute; tomar medidas y recaudos para proteger su informaci&oacute;n. Insistimos en tomar todo tipo de precauci&oacute;n para proteger su informaci&oacute;n personal mientras se encuentra en Internet. Adem&aacute;s, aconsejamos cumplir los consejos citados en la secci&oacute;n &ldquo;Seguridad&rdquo; del Sitio.</span></p>
<p><span >Contacto</span></p>
<p><span >Si tiene preguntas sobre su privacidad en el Sitio y/o referidos a su cuenta personal, puede comunicarse a trav&eacute;s de la opci&oacute;n &ldquo;Contacto&rdquo;, que estaremos a disposici&oacute;n para responder sus inquietudes.</span></p>
<p><span >Cambios en las Pol&iacute;ticas de Privacidad</span></p>
<p><span >La Empresa se reserva el derecho a modificar esta Pol&iacute;tica de Privacidad peri&oacute;dicamente. Usted se compromete a revisar regularmente estas Pol&iacute;ticas de Privacidad a fin de informarse de cualquier cambio que se pueda haber producido.</span></p>
`
