import { NgModule } from '@angular/core';
import { DefaultUrlSerializer, RouterModule, Routes, UrlSerializer, UrlTree } from '@angular/router';
import { HttpService } from './shared/services/http/service';
import { PaymentTransactionService } from './shared/services/payment-transaction/payment-transaction.service';
import { AvailableService } from './shared/guards/available-service';
import { UnavailableService } from './shared/guards/unavailable-service';
const appRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "init-payment",
  },
  {
    path: "init-admin-payment",
    canActivate: [AvailableService],
    loadChildren: () =>
      import("./init-payment/init-payment.module").then(
        (m) => m.InitPaymentModule
      ),
  },
  {
    path: "init-admin-payment/:transaction",
    canActivate: [AvailableService],
    loadChildren: () =>
      import("./init-payment/init-payment.module").then(
        (m) => m.InitPaymentModule
      ),
  },
  {
    path: "init-payment",
    canActivate: [AvailableService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./init-payment/init-payment.module").then(
            (m) => m.InitPaymentModule
          ),
      },
      {
        path: ":transaction",
        loadChildren: () =>
          import("./init-payment/init-payment.module").then(
            (m) => m.InitPaymentModule
          ),
      },
    ],
  },
  {
    path: "review-payment/:transaction",
    canActivate: [AvailableService],
    loadChildren: () =>
      import("./review-payment/review-payment.module").then(
        (m) => m.ReviewPaymentModule
      ),
  },
  {
    path: "payment-method/:transaction",
    loadChildren: () =>
      import("./payment-method/payment-method.module").then(
        (m) => m.PaymentMethodModule
      ),
  },
  {
    path: "confirm-payment/:transaction",
    canActivate: [AvailableService],
    loadChildren: () =>
      import("./confirm-payment/confirm-payment.module").then(
        (m) => m.ConfirmPaymentModule
      ),
  },
  {
    path: "result-message",
    canActivate: [AvailableService],
    children: [
      {
        path: ":status",
        loadChildren: () =>
          import("./result-message/result-message.module").then(
            (m) => m.ResultMessageModule
          ),
      },
      {
        path: ":status/:transaction",
        loadChildren: () =>
          import("./result-message/result-message.module").then(
            (m) => m.ResultMessageModule
          ),
      },
      {
        path: ":status/:transaction/:code",
        loadChildren: () =>
          import("./result-message/result-message.module").then(
            (m) => m.ResultMessageModule
          ),
      },
      {
        path: "ticket/:transaction",
        loadChildren: () =>
          import("./result-message/result-message.module").then(
            (m) => m.ResultMessageModule
          ),
      },
    ],
  },
  {
    path: "unsubscribe/:hash",
    loadChildren: () =>
      import("./unsubscribe/unsubscribe.module").then(
        (m) => m.UnsubscribeModule
      ),
  },
  {
    path: "unsubscribe-commercial-message/:hash",
    loadChildren: () =>
      import(
        "./unsubscribe-commercial-message/unsubscribe-commercial-message.module"
      ).then((m) => m.UnsubscribeCommercialMessageModule),
  },
  {
    path: "comprobantes",
    loadChildren: () =>
      import("./download-receipts/download-receipts.module").then(
        (m) => m.DownloadReceiptsModule
      ),
  },
  {
    path: "Comprobantes",
    loadChildren: () =>
      import("./download-receipts/download-receipts.module").then(
        (m) => m.DownloadReceiptsModule
      ),
  },
  {
    path: "invite",
    loadChildren: () =>
      import("./accession/accession.module").then((m) => m.AccessionModule),
  },
  {
    path: "unavailable-message",
    canActivate: [UnavailableService],
    loadChildren: () =>
      import("./unavailable-message/unavailable-message.module").then(
        (m) => m.UnavailableMessageModule
      ),
  },
  {
    path: "program-referred",
    canActivate: [AvailableService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./init-payment/init-payment.module").then(
            (m) => m.InitPaymentModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: "legacy" }),
  ],
  providers: [HttpService, PaymentTransactionService],
  exports: [RouterModule],
})
export class AppRoutingModule {}
